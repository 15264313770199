import { useContext, useEffect, useState } from 'react';
import styles from './Veriff.module.css'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { PostVeirff } from '../../../../Services/service';
import { GetAccessToken, getMe, isLogedIn, RefreshToken } from '../../../../Services/common';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../Services/userContext';
import { WebCamIcon, ChatIcon, PhotoIcon } from '../../../../assets/svg/svg';
import { useTranslation } from "react-i18next";


const Veriff = (props) => {
    const history = useNavigate()
    const [t] = useTranslation()
    const [veriffFrame, setVeriffFrame] = useState()
    const [isSelected, setIsSelected] = useState("LIVE")
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const { changeUser, setChangeUser } = useContext(UserContext);
	const { userData, setUserData } = useContext(UserContext);

    useEffect(() => {
        if (userData && userData.verified != undefined) {
            if (isLogedIn() && (userData.verified==="verified")) {
                history("/");
            }
        }
    }, [userData])


    function getVerrifUrl(tries = 0) {
        if (isLogedIn()) {
			const accessToken = GetAccessToken();
			if (accessToken) {
                setIsButtonLoading(true);
				PostVeirff(accessToken)
					.then((response) => {
                        setVeriffFrame(
                            createVeriffFrame({
                                url: response.data.verificationUrl,
                                onEvent: function(msg) {
                                    switch(msg) {
                                        case MESSAGES.STARTED:
                                            console.log("VERIFF started");
                                            break;
                                        case MESSAGES.CANCELED:
                                            console.log("VERIFF canceled");
                                            break;
                                        case MESSAGES.FINISHED:
                                            console.log("VERIFF finished");
                                            setTimeout(() => {
                                                setChangeUser(true)
                                            },1000)
                                            break;
                                        case MESSAGES.RELOAD_REQUEST:
                                            console.log("VERIFF reload");
                                            window.location.reload();
                                            break;
                                        default:
                                            console.log("VERIFF default");
                                            break;
                                    }
                                },
                                onReload: () => {
                                    window.location.reload();
                                },
                            })
                        )
					})
                    .catch((error) => {
                        RefreshToken().then((resp) => {
                            if (!resp) {
                                history("/login");
                            } else {
                                if (tries < 4) {
                                    getVerrifUrl(tries + 1);
                                } else {
                                    history("/login");
                                }
                            }
                        });
					})
                    .finally(() => {
                        setIsButtonLoading(false);
                    })
			} else {
				history("/login");
			}
		} else {
			history("/login");
        }
    }

    function getSessionId(tries = 0) {
        if (isLogedIn()) {
			const accessToken = GetAccessToken();
			if (accessToken) {
                setIsButtonLoading(true);
				PostVeirff(accessToken)
					.then((response) => {
                        history("/verify/upload", {state: response.data.sessionId})
					})
                    .catch((error) => {
                        RefreshToken().then((resp) => {
                            if (!resp) {
                                history("/login");
                            } else {
                                if (tries < 4) {
                                    getSessionId(tries + 1);
                                } else {
                                    history("/login");
                                }
                            }
                        });
					})
                    .finally(() => {
                        setIsButtonLoading(false);
                    })
			} else {
				history("/login");
			}
		} else {
			history("/login");
        }
    }

    function submit(params) {
        switch (isSelected) {
            case "LIVE":
                getVerrifUrl();
                break;
            case "CHAT":
                window._dixa_.invoke('setWidgetOpen', true);
                break;
            case "UPLOAD":
                getSessionId()
                break;
            default:
                break;
        }
    }
    
    return (
        <div className={styles.veriffContainer}>
            <h1 className={styles.veriffHeader}>{t("verify.header")}</h1>
            <div className={styles.cardsContainer}>
                <div onClick={() => { setIsSelected("LIVE")}}  className={styles.card + " " + (isSelected === "LIVE" ? styles.selected : "")}>
                    <div className={styles.header}>
                        <div className={styles.headerLeft}>
                            <WebCamIcon />
                            {t("veriff.live")}
                        </div>
                        <div className={styles.select + " " + (isSelected === "LIVE" ? styles.selected : "")}></div>
                    </div>
                    <ul className={styles.body}>
                        <li className={styles.bulletPoints}>{t("veriff.select.document")}</li>
                        <li className={styles.bulletPoints}>{t("veriff.upload.image")}</li>
                        <li className={styles.bulletPoints}>{t("veriff.take.selfie")}</li>
                    </ul>
                </div>
                <div onClick={() => { setIsSelected("UPLOAD")}} className={styles.card + " " + (isSelected === "UPLOAD" ? styles.selected : "")}>
                    <div className={styles.header}>
                        <div className={styles.headerLeft}>
                            <PhotoIcon />
                            {t("veriff.upload")}
                        </div>
                        <div className={styles.select + " " + (isSelected === "UPLOAD" ? styles.selected : "")}></div>
                    </div>
                    <ul className={styles.body}>
                        <li className={styles.bulletPoints}>{t("veriff.upload.image")}</li>
                        <li className={styles.bulletPoints}>{t("veriff.upload.selfie.document")}</li>
                    </ul>
                </div>
                <div onClick={() => { setIsSelected("CHAT")}} className={styles.card + " " + (isSelected === "CHAT" ? styles.selected : "")}>
                    <div className={styles.header}>
                        <div className={styles.headerLeft}>
                            <ChatIcon />
                            {t("veriff.chat")}
                        </div>
                        <div className={styles.select + " " + (isSelected === "CHAT" ? styles.selected : "")}></div>
                    </div>
                    <ul className={styles.body}>
                        <li className={styles.bulletPoints}>{t("veriff.upload.image")}</li>
                        <li className={styles.bulletPoints}>{t("veriff.upload.selfie.document")}</li>
                    </ul>
                </div>
            </div>
            <button onClick={submit} className={styles.submitButton + " " +(isButtonLoading ? styles.disabled : "")} type="submit">
                {isButtonLoading?
                    <div className={styles.spinLoading}>
                        <div className={styles.spinLoadingOuterBlock}>
                            <div className={styles.spinLoadingBlock + " " + styles.one}></div>
                        </div>
                        <div className={styles.spinLoadingOuterBlock}>
                            <div className={styles.spinLoadingBlock + " " + styles.two}></div>
                        </div>
                        <div className={styles.spinLoadingOuterBlock}>
                            <div className={styles.spinLoadingBlock + " " + styles.three}></div>
                        </div>
                    </div>
                :
                    t("continue")
                }
            </button>
       </div>
    )
}

export default Veriff;