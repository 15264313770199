import { useContext, useEffect, useState } from "react";
import { isLogedIn } from "./common";
import { Outlet, Navigate } from 'react-router-dom';
import { UserContext } from "./userContext";

const VeriffRouter = ({ component: Component, ...rest }) => {
    const [showVeriff, setShowVeriff] = useState(false)
	const { userData, setUserData } = useContext(UserContext);

  useEffect(() => {
    // if (setShowVeriff) {
    if (userData && userData.verified != undefined) {
        setShowVeriff(!(userData.verified==="verified"))
    }
    // }
  }, [userData])
    
  return (
      !showVeriff ?  <Outlet/>  : <Navigate to="/" replace />
  );
};

export default VeriffRouter;
