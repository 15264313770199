import styles from "./Details.module.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { isLogedIn, SetAccessToken, SetItemToLocalStorage, SetRefreshToken } from "../../../../Services/common";
import Input from "../../../../Components/Mobile/Input/Input";
import { BackIcon, EyeIcon, EyeOffIcon, NotValidIcon, ValidIcon } from "../../../../assets/svg/svg";
import { PostRegister } from "../../../../Services/service";
import { UserContext } from "../../../../Services/userContext";
import { Trans, useTranslation } from "react-i18next";
import WarningPopup from "../../../../Components/Mobile/WarningPopup/WarningPopup";

const Details = () => {
    const [t, i18n] = useTranslation();
    const history = useNavigate();
    const location = useLocation();
    const passwordRef = useRef();
    const passwordRepeatRef = useRef();
    const [showPopup, setShowPopup] = useState()
    const [isMinValid, setIsMinValid] = useState(false);
    const [hidePssword, setHidePssword] = useState(true);
    const [hidePsswordRepeat, setHidePsswordRepeat] = useState(true);
    const [isSpecValid, setIsSpecValid] = useState(false);
    const [isUppercaseValid, setUppercaseValid] = useState(false);
    const [isNumValid, setIsNumValid] = useState(false);
    // const [isAllFilled, setIsAllFilled] = useState(false);
    const { setChangeUser } = useContext(UserContext);
    const { wallet, setWallet } = useContext(UserContext);

    const checkRef1 = useRef()
    const checkRef2 = useRef()
    useEffect(() => {
        if (!location.state) {
            history("/register");
        }
    },[])
    const id = location.state ? location.state.id : '';

    const registerSchema = Yup.object().shape({
        username: Yup.string().min(6,t('must.be.longer.then.6')).max(30,t('must.be.shorter.then.30')).required(t('username.required')),
        email: Yup.string().matches(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ,t('email.invalid')).required(t('email.required')).max(63,t('must.be.shorter.then.63')).email(t('email.invalid')),
        password: Yup.string().required(t('password.required')),
        passwordRepeat: Yup.string().equals([Yup.ref('password')],t('passwords.not.match')).required(t('password.repeat.required')),
    });
    // const [signIn] = useMutation(SIGN_IN_MUTATION);

    useEffect(() => {
        if (isLogedIn()) {
            history("/");
        }
    }, [history]);

    useEffect(() => {
        const passwordElement = passwordRef.current;
        const handleEvent = ( event ) => {
            const val = event.target.value;
            val.length >= 8 ? setIsMinValid(true) : setIsMinValid(false)
            val.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]/) !== null ? setIsSpecValid(true) : setIsSpecValid(false)
            val.match(/[A-Z]/) !== null && val.match(/[a-z]/) !== null ? setUppercaseValid(true) : setUppercaseValid(false)
            val.match(/\d+/) !== null ? setIsNumValid(true) : setIsNumValid(false)
            
        };
        passwordElement.addEventListener( 'keyup', handleEvent );
        return () => passwordElement.removeEventListener( 'keyup', handleEvent )
    }, []);

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
            email: "",
            passwordRepeat: ""
        },
        validationSchema: registerSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (data) => {
            if (isMinValid && isSpecValid && isUppercaseValid && isNumValid) {
                if (checkRef2.current.checked) {
                    PostRegister(id, data.username, data.password, data.email, checkRef1.current.checked, i18n.language)
                        .then((resp) => {
                            const { access_token, refresh_token} = resp.data;
                            SetRefreshToken(refresh_token);
                            SetAccessToken(access_token);
                            setChangeUser(true);
                            setWallet(null);
                            history("/")
                        })
                        .catch((error) => {
                            const yupErrors = {};
                            switch (error.response && error.response.status) {
                                case 400:
                                    yupErrors.password = t('password.not.strong');
                                    break;
                                case 409:
                                    yupErrors.username = t('username.exists');
                                    break
                                case 401:
                                    history("/register");
                                    break
                                default:
                                    break;
                            }
                            if (yupErrors != null) {
                                formik.setErrors(yupErrors);
                            }
                        })
                } else {
                    if (!checkRef2.current.checked) {
                        checkRef2.current.style.border = "1.5px solid rgba(220, 61, 67, 1)"; 
                    }
                }
            } else {
                const yupErrors = {};
                yupErrors.password = t('password.not.strong');
                yupErrors.passwordRepeat = t('password.not.strong');
                formik.setErrors(yupErrors);
            }
            // console.log("formSubmit");
            // signIn({ variables: { username: data.username, personalId: data.personalId } })
            //   .then((data) => {
            //     const { accessToken } = data.data.signIn;
            //     SetItemToLocalStorage('accessToken', accessToken);
            //     history("/providers")
            //   })
            //   .catch((error) => {
            //     if (error.message === "exists") {
            //       const yupErrors = {};
            //       yupErrors.username = "incorrect username or personalId";
            //       yupErrors.personalId = "incorrect username or personalId";
            //       formik.setErrors(yupErrors)
            //     } else {
            //       console.log(error)
            //     }
            //   });
        },
    });

    // useEffect(() => { 
    //     setIsAllFilled(true)
    //     for (let index = 0; index < Object.values(formik.values).length; index++) {
    //         const element = Object.values(formik.values)[index];
    //         if (element.length === 0) {
    //             setIsAllFilled(false);
    //         }
    //     }

    // }, [formik.values])

    const { errors, touched, setFieldValue , /*isSubmitting,*/ handleSubmit } = formik;

    return (
        <div className={styles.detailsWrapper}>
            {showPopup && <WarningPopup warningHeader={"back.warning.header"} warningText={"back.warning.text"} buttonSubmit={"submit.back"} buttonCancel={"cancel"} showPopup={showPopup} setShowPopup={setShowPopup} submit={() => { history('/register') }} />}
            <div className={styles.headerContainer}>
                <div onClick={() => setShowPopup(true)} className={styles.backIcon}><BackIcon/></div>
                <span className={styles.aviatorLogo}></span>
            </div>
            <h1 className={styles.detailsHeader}>{t("account.details")}</h1>
            {/* <p className={styles.text}>შეიყვანე კოდი რომელიც გამოგიგზავნეთ, თქვენს მითითებულ ტელეფონის ნომერზე 5995*****31</p> */}
            <FormikProvider  validateOnBlur value={formik}>
                <div className={styles.form}>
                    <Form onSubmit={handleSubmit}>
                        <Input
                            className={styles.input}
                            type={"text"}
                            name={"username"}
                            value={formik.values.username}
                            onChange={e => {
                                e.preventDefault()
                                const { value } = e.target;
                                const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
                                if ( regex.test(value.toString()) || value === "" ) {
                                  setFieldValue("username", value);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            id={"username"}
                            error={errors.username}
                            touched={touched.username}
                            floatingLabel={t("username")}
                            autoComplete={"off"}
                        />
                        <Input
                            className={styles.input}
                            type={"email"}
                            name={"email"}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id={"email"}
                            error={errors.email}
                            touched={touched.email}
                            floatingLabel={t("email")} 
                            autoComplete={"off"}
                        />
                        <div className={styles.inputContainer}>
                            <Input
                                type={"text"}
                                name={"password"}
                                ref={passwordRef}
                                hidePassword={hidePssword}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id={"password"}
                                error={errors.password}
                                touched={touched.password}
                                floatingLabel={t("password")}
                                autoComplete={"off"}
                            />
                                <span
                                    onClick={() => {
                                        setHidePssword(!hidePssword)
                                    }}
                                    className={styles.eyeIcon}
                                >{!hidePssword ? <EyeIcon color={"rgba(119, 119, 119, 1)"}/> : <EyeOffIcon color={"rgba(119, 119, 119, 1)"}/>}</span>
                        </div>
                        <div className={styles.validationContainer}>
                            <div className={styles.validation}>
                                <span className={styles.validationIcon }>{ isMinValid? <ValidIcon/> : <NotValidIcon/>}</span>
                                <p className={styles.validationText}>{t("password.min.8.char")} </p>
                            </div>
                            <div className={styles.validation}>
                                <span className={styles.validationIcon }>{ isUppercaseValid? <ValidIcon/> : <NotValidIcon/>}</span>
                                <p className={styles.validationText}>{t("password.eng.char")}</p>
                            </div>
                            <div className={styles.validation}>
                                <span className={styles.validationIcon}>{ isNumValid? <ValidIcon/> : <NotValidIcon/>}</span>
                                <p className={styles.validationText}>{t("password.must.num")}</p>
                            </div>
                            <div className={styles.validation}>
                                <span className={styles.validationIcon}>{ isSpecValid? <ValidIcon/> : <NotValidIcon/>}</span>
                                <p className={styles.validationText}>{t("password.must.special.symbol")}</p>
                            </div>
                        </div>
                        
                        <div className={styles.inputContainer}>
                            <Input
                                type={"text"}
                                name={"passwordRepeat"}
                                ref={passwordRepeatRef}
                                hidePassword={hidePsswordRepeat}
                                value={formik.values.passwordRepeat}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id={"passwordRepeat"}
                                error={errors.passwordRepeat}
                                touched={touched.passwordRepeat}
                                floatingLabel={t("repeat.password")}
                                autoComplete={"off"}
                            />
                                <span
                                    onClick={() => {
                                        setHidePsswordRepeat(!hidePsswordRepeat)
                                    }}
                                    className={styles.eyeIcon}
                                >{!hidePsswordRepeat ? <EyeIcon color={"rgba(119, 119, 119, 1)"}/> : <EyeOffIcon color={"rgba(119, 119, 119, 1)"}/>}</span>
                        </div>
                        <div className={styles.footer}>
                            <div className={styles.footerTextDiv}>
                                <div className={styles.footerText}>
                                    <Trans i18nKey="accept.marketing">ვეთანხმები მივიღო “ავიატოორ ბეთის”-სგან განახლებები და <NavLink className={styles.footerLink}>მარკეტინგული შეტყობიბები</NavLink></Trans>
                                </div>
                                <input ref={checkRef1} className={styles.checkbox} defaultChecked={true} type="checkbox"></input>
                            </div>
                            <div className={styles.footerTextDiv}>
                                <div className={styles.footerText}>
                                <Trans i18nKey="accept.rules">რეგისტრაციით ეთანხმები შპს “ავიატორ ბეთის” გამოყენების <NavLink className={styles.footerLink}>წესებსა და პირობებს </NavLink> </Trans>
                                </div>
                                <input ref={checkRef2} className={styles.checkbox} type="checkbox"></input>
                            </div>
                            <button type="submit" className={styles.submitButton /* + " " +(isAllFilled? "": styles.disabled) */}>
                                {t("continue")}
                            </button>
                        </div>
                    </Form>
                </div>
            </FormikProvider>
        </div>
    );
};

export default Details;
