import { useState } from 'react';
import styles from './Upload.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ID from '../../../../Components/Mobile/ID/ID';
import Passport from '../../../../Components/Mobile/Passport/Passport';
import Popup from '../../../../Components/Mobile/Popup/Popup';
import { imageSubmit, sendImage } from '../../../../Services/common';


const Upload = (props) => {
    const history = useNavigate()
    const [t] = useTranslation()
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState();
    const [popupOpenedFor, setPopupOpenedFor] = useState();
    const translations = {"FRONT": "upload.front", "BACK": "upload.back", "SELFY": "upload.selfy", "PASSPORT": "upload.passport", "PASSPORT.SELFY": "upload.selfy"}
    const [frontImage, setFrontImage] = useState();
    const [frontImageUploaded, setFrontImageUploaded] = useState(false);
    const [backImage, setBackImage] = useState();
    const [backImageUploaded, setBackImageUploaded] = useState(false);
    const [selfyImage, setSelfyImage] = useState();
    const [selfyImageUploaded, setSelfyImageUploaded] = useState(false);
    const [passportImage, setPassportImage] = useState();
    const [passportImageUploaded, setPassportImageUploaded] = useState(false);
    const [passportSelfyImage, setPassportSelfyImage] = useState();
    const [passportSelfyImageUploaded, setPassportSelfyImageUploaded] = useState(false);
    const [isAllImageSubmited, setIsAllImageSubmited] = useState(false);
    const sessionId = location.state;

    function uploadImg(event) {
        const reader = new FileReader();
        switch (popupOpenedFor) {
            case "FRONT":
                setFrontImageUploaded(false);
                setFrontImage(event.target.files[0]);
                reader.onloadend = () => {
                    sendImage(sessionId, reader.result, "document-front")
                        .then((response) => {
                            if (response === "true") {
                                setFrontImageUploaded(true);
                            }
                        })
                        .catch((error) => { 
                            console.log(error);  
                        })  
                };
                reader.readAsDataURL(event.target.files[0]);
                break;
            case "BACK":
                setBackImageUploaded(false);
                setBackImage(event.target.files[0]);
                reader.onloadend = () => {
                    sendImage(sessionId, reader.result, "document-back")
                        .then((response) => {
                            if (response === "true") {
                                setBackImageUploaded(true);
                            }
                        })
                        .catch((error) => { 
                            console.log(error);  
                        })  
                };
                reader.readAsDataURL(event.target.files[0]);
                break;
            case "SELFY":
                setSelfyImageUploaded(false);
                setSelfyImage(event.target.files[0]);
                reader.onloadend = () => {
                    sendImage(sessionId, reader.result, "face")
                        .then((response) => {
                            if (response === "true") {
                                setSelfyImageUploaded(true);
                            }
                        })
                        .catch((error) => { 
                            console.log(error);  
                        })
                };
                reader.readAsDataURL(event.target.files[0]);
                break;
            case "PASSPORT":
                setPassportImageUploaded(false)
                setPassportImage(event.target.files[0]);
                reader.onloadend = () => {
                    sendImage(sessionId, reader.result, "document-front")
                        .then((response) => {
                            if (response === "true") {
                                setPassportImageUploaded(true);
                            }
                        })
                        .catch((error) => { 
                            console.log(error);  
                        }) 
                };
                reader.readAsDataURL(event.target.files[0]);
                break;
            case "PASSPORT.SELFY":
                setPassportSelfyImageUploaded(false)
                setPassportSelfyImage(event.target.files[0]);
                reader.onloadend = () => {
                    sendImage(sessionId, reader.result, "face")
                        .then((response) => {
                            if (response === "true") {
                                setPassportSelfyImageUploaded(true);
                            }
                        })
                        .catch((error) => { 
                            console.log(error);  
                        }) 
                };
                reader.readAsDataURL(event.target.files[0]);
                break;
            default:
                break;
        }
        setIsPopupOpen(false);
    }

    function clearImages() {
        setFrontImage(null);
        setBackImage(null);
        setSelfyImage(null);
        setPassportImage(null);
        setPassportSelfyImage(null);
        setFrontImageUploaded(false);
        setBackImageUploaded(false);
        setSelfyImageUploaded(false);
        setPassportImageUploaded(false);
    }

    useState(() => {
        if (activeTab === 0) {
            if (frontImageUploaded && backImageUploaded && selfyImageUploaded) {
                setIsAllImageSubmited(true);
            } else {
                setIsAllImageSubmited(false)
            }
        } else {
            if (passportImageUploaded && passportSelfyImageUploaded) {
                setIsAllImageSubmited(true);
            } else {
                setIsAllImageSubmited(false);
            }
        }
    },[frontImageUploaded,backImageUploaded,selfyImageUploaded,passportImageUploaded,passportSelfyImageUploaded])

    function submit() {
        if (isAllImageSubmited) {
            imageSubmit(sessionId)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => { 
                    console.log(error);  
                })
        }
    }

    return (
        <>
            <div className={styles.veriffContainer}>
                <div>
                    <h1 className={styles.veriffHeader}>{t("veriff.upload")}</h1>
                    <h1 className={styles.veriffHeaderText}>{t("veriff.upload.text")}</h1>
                    <header className={styles.switchContainer}>
                        <div onClick={() => { setActiveTab(0); clearImages() }} className={styles.switchContainerElement + (activeTab === 0 ? " " + styles.active : "")} >{t("id.card")}</div>
                        <div onClick={() => { setActiveTab(1); clearImages() }} className={styles.switchContainerElement + (activeTab === 1 ? " " + styles.active : "")} >{t("passport")}</div>
                    </header>
                    <div className={styles.activityContainer}>
                        {activeTab === 0 ?
                            <ID
                                frontImage={frontImage} backImage={backImage} selfyImage={selfyImage}
                                setFrontImage={setFrontImage} setBackImage={setBackImage} setSelfyImage={setSelfyImage}
                                setIsPopupOpen={setIsPopupOpen} setPopupOpenedFor={setPopupOpenedFor}
                            /> :
                            <Passport
                                passportImage={passportImage} passportSelfyImage={passportSelfyImage}
                                setPassportImage={setPassportImage} setPassportSelfyImage={setPassportSelfyImage}    
                                setIsPopupOpen={setIsPopupOpen} setPopupOpenedFor={setPopupOpenedFor}
                            />}
                    </div>
                </div>
                <button onClick={submit} className={styles.submitButton + " " + (isAllImageSubmited ?  "" : styles.disabled)} type="submit">
                                {t("continue")}
                </button>
            </div>
            {isPopupOpen &&
                <Popup header={translations[popupOpenedFor]} hidePopup={setIsPopupOpen}>
                    <div className={styles.popupOptions}>
                        <label>
                            <div className={styles.popupOption}>
                                <p>{t("take.picture")}</p>
                                {popupOpenedFor === "SELFY" || popupOpenedFor === "PASSPORT.SELFY" ? 
                                    <input
                                        type="file"
                                        name="myImage"
                                        accept="image/*"
                                        capture="user"
                                        style={{display:'none'}}
                                        onChange={(event) => uploadImg(event)}
                                    />
                                    :
                                        <input
                                        type="file"
                                        name="myImage"
                                        accept="image/*"
                                        capture="environment"
                                        style={{display:'none'}}
                                        onChange={(event) => uploadImg(event)}
                                    />
                                }
                            </div>
                        </label>
                        <label>
                            <div className={styles.popupOption}>
                                <p>{t("upload.from.galery")}</p>
                                <input
                                    type="file"
                                    name="myImage"
                                    accept="image/*"
                                    style={{display:'none'}}
                                    onChange={(event) => uploadImg(event)}
                                />
                            </div>
                        </label>
                    </div>
                </Popup>
            }
        </>
    )
}

export default Upload;