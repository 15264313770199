import React from 'react';
// import CustomSlider from "../../../Components/Slider/Slider";
// import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
// import MobileSlider from "../../../Components/Mobile/MobileSlider/MobileSlider";
import MobileGamesContainer from "../../../Components/Mobile/MobileGamesContainer/MobileGamesContainer";
// import SlotsDescription from "../../../Components/Descriptions/SlotsDescription";

const Slots = () => {
    return (
        <>
            {/* <SlotsDescription/> */}
            {/* <MobileSlider pageName={'Slots'}/> */}
            <MobileGamesContainer categoryName={'Slots'}/>
        </>
    );
};

export default Slots;
