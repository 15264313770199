import React from 'react';
import {Link, useLocation} from "react-router-dom";
import styles from "./MobileHeaderLogo.module.css";

const MobileHeaderLogo = () => {
    const location = useLocation()
    function closeDixa(params) {
        window._dixa_.invoke('setWidgetOpen', false);
    }
    return (
        <Link className={location.pathname === '/verify' ? styles.disabled : ""} to={"/"} onClick={closeDixa}>
            <div className={styles.aviatorLogo}/>
        </Link>
    );
};

export default MobileHeaderLogo;
