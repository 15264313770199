import { useContext, useEffect } from "react";
import ActivityComponent from "../../../Components/Mobile/Activity/Activity";
import styles from './Activity.module.css'
import { UserContext } from "../../../Services/userContext";
import { isLogedIn } from "../../../Services/common";
import { useNavigate } from "react-router-dom";

const ActivityPage = (props) => {
    const { userData, setUserData } = useContext(UserContext);
    const history = useNavigate();

    useEffect(() => {
        if (userData && userData.verified != undefined) {
            if (isLogedIn() && !(userData.verified==="verified")) {
                history("/verify");
            }
        }
    }, [userData])
    return (
        <div className={styles.activityContainer}>
            <ActivityComponent/>
        </div>
    )
}

export default ActivityPage;
