import { useEffect, useRef, useState } from "react";
import styles from "./PhoneTwoFa.module.css";
import { BackIcon , CloseIcon } from "../../../assets/svg/svg";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { Form } from "react-router-dom";
import * as Yup from "yup";
import { PostPhoneUpdateCheck } from "../../../Services/service";
import { phoneUpdateCheck } from "../../../Services/common";


const PhoneTwoFa = ({ setTwoFaError, userData, setUserData, setShowPopup, setIsTwoFaAccepted, setIsPhonePopupOpen, userInput }) => {
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const [fadeOut, setFadeOut] = useState(false);
    const [t] = useTranslation();
    const [isError, setIsError] = useState(false)


    // useEffect(() => {
    //     inputRef.current.focus();
    // }, []);

    function hidePopup(element, isIcon) {
        if (element.target === wrapperRef.current || isIcon) {
            setFadeOut(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 500);
        }
    }

    function inputChange(input) {
        const regex = /^[0-9]$/;
        input.preventDefault();
        input.stopPropagation();
        if (input.keyCode === 8 && input.target.value === "") {
            if (input.target.parentElement.previousElementSibling) {
                input.target.parentElement.previousElementSibling.children[0].focus()
                return;
            }
        } else if (input.keyCode === 8) {
            setFieldValue(input.target.name, "")
            return;
        }

        if (regex.test(input.key.toString())) {
            setFieldValue(input.target.name, input.key)
            if (isError) {
                setIsError(false);
            }
            if (input.target.parentElement.nextElementSibling) {
                input.target.parentElement.nextElementSibling.children[0].focus()
            }
        }
    }

    const formik = useFormik({
        initialValues: {
        },
    });

    function handleSubmit(params) {
        const code = formik.values.number1 + formik.values.number2 + formik.values.number3 + formik.values.number4
        phoneUpdateCheck(userInput.numberCode + userInput.number, code, String(userInput.personalId), userInput.password)
            .then((resp) => {
                if (resp && resp.phone) {
                    setUserData({ ...userData, phone: resp.phone})
                    setIsTwoFaAccepted(true);
                    setFadeOut(true);
                    setTimeout(() => {
                        setShowPopup(false);
                    }, 500);
                } else {
                    if (resp.response && resp.response.status === 400) {
                        const yupErrors = {};
                        yupErrors.personalId = t("account.update.error");
                        yupErrors.number = t("account.update.error");
                        yupErrors.password = t("account.update.error");
                        setTwoFaError(yupErrors);
                        setFadeOut(true);
                        setTimeout(() => {
                            setShowPopup(false);
                        }, 500);
                        setIsPhonePopupOpen(true);
                    }
                    if (resp.response && resp.response.data.message === "OTP_CODE_NOT_VALID") {
                        setIsError(true)
                    }
                }
            })
    }
    const {setFieldValue } = formik;


    return (
        <div onClick={(event) => hidePopup(event, false)} ref={wrapperRef} className={styles.popupComponent}>
            <div className={styles.popupContainer + (fadeOut ? " " + styles.fadeOut : "")}>   
                <div className={styles.headerDiv}>
                    <div className={styles.headerLeft}>
                        <div onClick={(event) => { hidePopup(event, true); setIsPhonePopupOpen(true);}} className={styles.backIcon}><BackIcon/></div>
                        <h5 className={styles.headerText}>{t("SMS.code")}</h5>
                    </div>
                    <span
                        onClick={(event) => hidePopup(event, true)}
                        className={
                            styles.popupContainerIcon
                        }
                    ><CloseIcon/></span>
                </div>
                <div className={styles.form}>
                        <p className={styles.text}>{t("enter.code.from.mobile")} +9955990***39</p>
                            <div className={styles.inputs}>
                                <div className={styles.codeInputContainer}>
                                    <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")} name="number1" value={formik.values.number1} onKeyDown={(input) => { inputChange(input) }} type="number" maxLength="1"></input>
                                    <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                                </div>
                                <div className={styles.codeInputContainer}>
                                    <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")} name="number2" value={formik.values.number2}  onKeyDown={(input) => {inputChange(input)}} type="number" maxLength="1"></input>
                                    <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                                </div>
                                <div className={styles.codeInputContainer}>
                                    <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")}  name="number3" value={formik.values.number3} onKeyDown={(input) => {inputChange(input)}} type="number" maxLength="1"></input>
                                    <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                                </div>
                                <div className={styles.codeInputContainer}>
                                    <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")}  name="number4" value={formik.values.number4} onKeyDown={(input) => {inputChange(input)}} type="number" maxLength="1"></input>
                                    <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                                </div>
                            </div>
                    </div>

                <button type="submit" onClick={() => handleSubmit()} className={styles.submitButton}>
                    {t("continue")}
                </button>
            </div>
        </div>
    );
};

export default PhoneTwoFa;
