export const crashGamesArray = [
    {
        id: "ac84b652-8d41-4dc2-aad7-42fcd12d89ce",
        createdAt: "2024-08-08T11:11:55.438Z",
        updatedAt: "Thu Aug 08 2024 11:12:12 GMT+0000 (Coordinated Universal Time)",
        name: "Aviator",
        order: "0",
        orderByPopularity: "1",
        orderByNew: "0",
        orderByHome: "0",
        isNew: "true",
        isPopular: "false",
        isHome: "false",
        active: "true",
        provider: "AVIATOR",
        gameId: "1",
        portalCode: "AviatorbetGE_STG_Portal_GEL",
        thumbnail: "https://i.ibb.co/pdf5rTR/v2.png",
        openMode: "newTab",
        deletedAt: ""
    },
    {
        id: "ac84b652-8d41-4dc2-aad7-42fcd12d89ce",
        createdAt: "2024-08-08T11:11:55.438Z",
        updatedAt: "Thu Aug 08 2024 11:12:12 GMT+0000 (Coordinated Universal Time)",
        name: "",
        order: "0",
        orderByPopularity: "1",
        orderByNew: "0",
        orderByHome: "0",
        isNew: "true",
        isPopular: "false",
        isHome: "false",
        active: "false",
        provider: "Coming soon",
        gameId: "1",
        portalCode: "AviatorbetGE_STG_Portal_GEL",
        thumbnail: "https://i.ibb.co/DL14R79/Group-62.png",
        openMode: "",
        deletedAt: ""
    },
    {
        id: "ac84b652-8d41-4dc2-aad7-42fcd12d89ce",
        createdAt: "2024-08-08T11:11:55.438Z",
        updatedAt: "Thu Aug 08 2024 11:12:12 GMT+0000 (Coordinated Universal Time)",
        name: "",
        order: "0",
        orderByPopularity: "1",
        orderByNew: "0",
        orderByHome: "0",
        isNew: "true",
        isPopular: "false",
        isHome: "false",
        active: "false",
        provider: "Coming soon",
        gameId: "1",
        portalCode: "AviatorbetGE_STG_Portal_GEL",
        thumbnail: "https://i.ibb.co/DL14R79/Group-62.png",
        openMode: "",
        deletedAt: ""
    },
    {
        id: "ac84b652-8d41-4dc2-aad7-42fcd12d89ce",
        createdAt: "2024-08-08T11:11:55.438Z",
        updatedAt: "Thu Aug 08 2024 11:12:12 GMT+0000 (Coordinated Universal Time)",
        name: "",
        order: "0",
        orderByPopularity: "1",
        orderByNew: "0",
        orderByHome: "0",
        isNew: "true",
        isPopular: "false",
        isHome: "false",
        active: "false",
        provider: "Coming soon",
        gameId: "1",
        portalCode: "AviatorbetGE_STG_Portal_GEL",
        thumbnail: "https://i.ibb.co/DL14R79/Group-62.png",
        openMode: "",
        deletedAt: ""
    },
    {
        id: "ac84b652-8d41-4dc2-aad7-42fcd12d89ce",
        createdAt: "2024-08-08T11:11:55.438Z",
        updatedAt: "Thu Aug 08 2024 11:12:12 GMT+0000 (Coordinated Universal Time)",
        name: "",
        order: "0",
        orderByPopularity: "1",
        orderByNew: "0",
        orderByHome: "0",
        isNew: "true",
        isPopular: "false",
        isHome: "false",
        active: "false",
        provider: "Coming soon",
        gameId: "1",
        portalCode: "AviatorbetGE_STG_Portal_GEL",
        thumbnail: "https://i.ibb.co/DL14R79/Group-62.png",
        openMode: "",
        deletedAt: ""
    },
    {
        id: "ac84b652-8d41-4dc2-aad7-42fcd12d89ce",
        createdAt: "2024-08-08T11:11:55.438Z",
        updatedAt: "Thu Aug 08 2024 11:12:12 GMT+0000 (Coordinated Universal Time)",
        name: "",
        order: "0",
        orderByPopularity: "1",
        orderByNew: "0",
        orderByHome: "0",
        isNew: "true",
        isPopular: "false",
        isHome: "false",
        active: "false",
        provider: "Coming soon",
        gameId: "1",
        portalCode: "AviatorbetGE_STG_Portal_GEL",
        thumbnail: "https://i.ibb.co/DL14R79/Group-62.png",
        openMode: "",
        deletedAt: ""
    },
    // {
    //     gameName: "Apollo",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "Apollo",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'green'
    // },
    // {
    //     gameName: "JetX",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "JetX",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'blue'
    // },
    // {
    //     gameName: "SpaceX",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "SpaceX",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'gold'
    // },
    // {
    //     gameName: "CrashDuelX",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "Crash Duel X",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'blue'
    // },
    // {
    //     gameName: "CrazyHuntX",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "Crazy HuntX",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'purple'
    // },
    // {
    //     gameName: "SlicerX",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "SlicerX",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'pink'
    // },
    // {
    //     gameName: "SpaceMan",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "Space Man",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'green'
    // },
    // {
    //     gameName: "PlinkoX",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "PlinkoX",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'blue'
    // },
    // {
    //     gameName: "PlinkoJocker",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "Plinko Jocker",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'gold'
    // },
    // {
    //     gameName: "FootballX",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "FootballX",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'purple'
    // },
    // {
    //     gameName: "Aviator",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "Aviator",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'gold'
    // },
    // {
    //     gameName: "PlinkoJocker",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "Plinko Jocker",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'gold'
    // },
    // {
    //     gameName: "FootballX",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "FootballX",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'purple'
    // },
    // {
    //     gameName: "Aviator",
    //     mobileImagePath: "https://hackneytuition.com/wp-content/uploads/2024/03/aviator-game-in-kenya-44_1.png",
    //     imageAltAttribute: "Aviator Banner",
    //     displayName: "Aviator",
    //     gameUrl: "https://api.staging.aviator.studio:81/",
    //     provider: "Aviator Studio",
    //     onlinePlayer: 25,
    //     gameTitleColor: 'gold'
    // },
]