import styles from "./NewPassword.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { isLogedIn} from "../../../../Services/common";
import { PostResetPhoneCheck } from "../../../../Services/service";
import Input from "../../../../Components/Mobile/Input/Input";
import { BackIcon, EyeIcon, EyeOffIcon, NotValidIcon, ValidIcon } from "../../../../assets/svg/svg";

const NewPassword = () => {
    const [t] = useTranslation();
    const history = useNavigate();
    const passwordRef = useRef();
    const location = useLocation()
    const passwordRepeatRef = useRef();
    const [isMinValid, setIsMinValid] = useState(false);
    const [hidePssword, setHidePssword] = useState(true);
    const [hidePsswordRepeat, setHidePsswordRepeat] = useState(true);
    const [isSpecValid, setIsSpecValid] = useState(false);
    const [isNumValid, setIsNumValid] = useState(false);
    const [isUppercaseValid, setUppercaseValid] = useState(false);
    const [isError, setIsError] = useState(false)
    const userData = location.state;


    // const formRef = useRef()
    let isShow = false;

    const registerSchema = Yup.object().shape({
        password: Yup.string().required(t("password.required")),
        passwordRepeat: Yup.string().equals([Yup.ref('password')],t("passwords.not.match")).required(t("password.repeat.required")),
    });
    // const [signIn] = useMutation(SIGN_IN_MUTATION);

    useEffect(() => {
        if (!location.state) {
            history("/password-reset");
        }
        window.history.replaceState({}, '')
    },[])

    useEffect(() => {
        if (isLogedIn()) {
            history("/");
        }
    }, [history]);

    useEffect(() => {
        const passwordElement = passwordRef.current;
        const handleEvent = ( event ) => {
            const val = event.target.value;
            val.length >= 8 ? setIsMinValid(true) : setIsMinValid(false)
            val.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]/) !== null ? setIsSpecValid(true) : setIsSpecValid(false)
            val.match(/[A-Z]/) !== null && val.match(/[a-z]/) !== null ? setUppercaseValid(true) : setUppercaseValid(false)
            val.match(/\d+/) !== null ? setIsNumValid(true) : setIsNumValid(false)

        };
        passwordElement.addEventListener( 'keyup', handleEvent );
        return () => passwordElement.removeEventListener( 'keyup', handleEvent )
    }, []);

    // function showPassword(ref) {
        // if (!isShow) {
        //     isShow = true;
        //     ref.current.type = "text";
        // } else {
        //     isShow = false;
        //     ref.current.type = "password";
        // }
    // }

    function inputChange(input) {
        const regex = /^[0-9]$/;
        input.preventDefault();
        input.stopPropagation();
        if (input.keyCode === 8 && input.target.value === "") {
            if (input.target.parentElement.previousElementSibling) {
                input.target.parentElement.previousElementSibling.children[0].focus()
                return;
            }
        } else if(input.keyCode === 8){
            setFieldValue(input.target.name, "")
            return;
        } 

        if (regex.test(input.key.toString())) {
            setFieldValue(input.target.name, input.key)
            if (isError) {
                setIsError(false);
            }
            if (input.target.parentElement.nextElementSibling) {
                input.target.parentElement.nextElementSibling.children[0].focus()
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            password: "",
            passwordRepeat: "",
        },
        validationSchema: registerSchema,
        onSubmit: (data) => {
            if (isMinValid && isSpecValid && isUppercaseValid && isNumValid) {
                const code = formik.values.number1 + formik.values.number2 + formik.values.number3 + formik.values.number4
                PostResetPhoneCheck(formik.values.password, code, userData.numberCode + userData.number)
                    .then((resp) => {
                        history("/login");
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 409) {
                            const yupErrors = {};
                            yupErrors.personalId = t("id.exists");
                            history("/register", { state: yupErrors });
                        }
                        if (error.response && error.response.data.message === "ATTEMPTS_EXCEEDED") {
                            const yupErrors = {};
                            yupErrors.number = t("attempts.exceeded");
                            history("/register", { state: yupErrors });
                        }
                        if (error.response && error.response.data.message === "OTP_CODE_NOT_VALID") {
                            setIsError(true)
                        }
                    })
            } else {
                const yupErrors = {};
                yupErrors.password = t('password.not.strong');
                yupErrors.passwordRepeat = t('password.not.strong');
                formik.setErrors(yupErrors);
            }
        },
    });

    const { errors, touched, setFieldValue, /*isSubmitting,*/ handleSubmit } = formik;

    return (
        <div className={styles.newPasswordWrapper}>
            <div className={styles.headerContainer}>
                <NavLink to={"/password-reset"} className={styles.backIcon}> <BackIcon/> </NavLink>
                <NavLink to={"/"} className={styles.aviatorLogo}></NavLink>
            </div>
            <h1 className={styles.newPasswordHeader}>{t("create.new.password")}</h1>
            <p className={styles.text}>{t("create.new.password.for.acc")}</p>
            <FormikProvider  validateOnBlur value={formik}>
                <div className={styles.form}>
                    <Form onSubmit={handleSubmit}>
                       
                        <div className={styles.inputContainer}>
                            <Input
                                type={"text"}
                                name={"password"}
                                ref={passwordRef}
                                hidePassword={hidePssword}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id={"password"}
                                error={errors.password}
                                touched={touched.password}
                                floatingLabel={t("password")}
                            />
                                <span
                                    onClick={() => {
                                        setHidePssword(!hidePssword)
                                    }}
                                    className={styles.eyeIcon}
                                >{!hidePssword ? <EyeIcon color={"rgba(119, 119, 119, 1)"}/> : <EyeOffIcon color={"rgba(119, 119, 119, 1)"}/>}</span>
                        </div>
                        <div className={styles.validationContainer}>
                            <div className={styles.validation}>
                                <span className={styles.validationIcon }>{ isMinValid? <ValidIcon/> : <NotValidIcon/>}</span>
                                <p className={styles.validationText}>{t("password.min.8.char")}</p>
                            </div>
                            <div className={styles.validation}>
                                <span className={styles.validationIcon }>{ isUppercaseValid? <ValidIcon/> : <NotValidIcon/>}</span>
                                <p className={styles.validationText}>{t("password.eng.char")}</p>
                            </div>
                            <div className={styles.validation}>
                                <span className={styles.validationIcon}>{ isNumValid? <ValidIcon/> : <NotValidIcon/>}</span>
                                <p className={styles.validationText}>{t("password.must.num")}</p>
                            </div>
                            <div className={styles.validation}>
                                <span className={styles.validationIcon}>{ isSpecValid? <ValidIcon/> : <NotValidIcon/>}</span>
                                <p className={styles.validationText}>{t("password.must.special.symbol")}</p>
                            </div>
                        </div>
                        
                        <div className={styles.inputContainer}>
                            <Input
                                type={"text"}
                                name={"passwordRepeat"}
                                ref={passwordRepeatRef}
                                hidePassword={hidePsswordRepeat}
                                value={formik.values.passwordRepeat}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id={"passwordRepeat"}
                                error={errors.passwordRepeat}
                                touched={touched.passwordRepeat}
                                floatingLabel={t("password")}
                            />
                                <span
                                    onClick={() => {
                                        setHidePsswordRepeat(!hidePsswordRepeat)
                                    }}
                                    className={styles.eyeIcon}
                            >{!hidePsswordRepeat ? <EyeIcon color={"rgba(119, 119, 119, 1)"} /> : <EyeOffIcon color={"rgba(119, 119, 119, 1)"}/>}</span>
                        </div>

                        <p className={styles.text}>{t("enter.code.from.mobile")} {userData? userData.numberCode + userData.number: ""}</p>

                        <div className={styles.inputs}>
                            <div className={styles.codeInputContainer}>
                                <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")} name="number1" value={formik.values.number1} onKeyDown={(input) => { inputChange(input) }} type="number" maxLength="1"></input>
                                <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                            </div>
                            <div className={styles.codeInputContainer}>
                                <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")} name="number2" value={formik.values.number2}  onKeyDown={(input) => {inputChange(input)}} type="number" maxLength="1"></input>
                                <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                            </div>
                            <div className={styles.codeInputContainer}>
                                <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")}  name="number3" value={formik.values.number3} onKeyDown={(input) => {inputChange(input)}} type="number" maxLength="1"></input>
                                <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                            </div>
                            <div className={styles.codeInputContainer}>
                                <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")}  name="number4" value={formik.values.number4} onKeyDown={(input) => {inputChange(input)}} type="number" maxLength="1"></input>
                                <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                            </div>
                        </div>

                        <button type="submit" className={styles.submitButton}>
                            {t("continue")}
                        </button>

                    </Form>
                </div>
            </FormikProvider>
        </div>
    );
};

export default NewPassword;
