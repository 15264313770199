import styles from "./Login.module.css";
import React, {useContext, useEffect, useRef, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
// import { SIGN_IN_MUTATION } from "../../../library/store/api";

import Input from "../../../../Components/Mobile/Input/Input";
import { isLogedIn, SetAccessToken, SetRefreshToken } from "../../../../Services/common";
import Switch from "../../../../Components/Mobile/Switch/Switch";
import { PostSignIn } from "../../../../Services/service";
import { CloseCircleIcon, EyeIcon, EyeOffIcon } from "../../../../assets/svg/svg";
import { UserContext } from "../../../../Services/userContext";
import TwoFA from "../../../../Components/Mobile/TwoFA/TwoFA";
import MobileHeaderLogo from "../../../../Components/Mobile/MobilHeaderLogo/MobileHeaderLogo";
// import { useMutation} from '@apollo/client';

const Login = () => {
    const [t] = useTranslation()
    const { setChangeUser } = useContext(UserContext);
    const [showPopup, setShowPopup] = useState();
    // const [isTwoFaActive, setIsTwoFaActive] = useState(true);
    const [isShow, setIsShow] = useState(false);
    const history = useNavigate();
    const passwordRef = useRef();
    const { wallet, setWallet } = useContext(UserContext);
    // const dispatch = useDispatch();

    const LoginSchema = Yup.object().shape({
        username: Yup.string().required(t("username.required")),
        password: Yup.string().required(t("password.required")),
    });
    // const [signIn] = useMutation(SIGN_IN_MUTATION);

    useEffect(() => {
        if (isLogedIn()) {
            history("/");
        }
    }, []);
    

    function showPassword() {
        if (!isShow) {
            setIsShow(true);
            passwordRef.current.type = "text";
        } else {
            setIsShow(false);
            passwordRef.current.type = "password";
        }
    }

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: (data) => {
            // setSubmitting(true)
        //   SetItemToLocalStorage("accessToken", "1234test");
            //   history("/");
            PostSignIn(data.username, data.password)
                .then((data) => {
                    if(data.data.accountExists){
                        setShowPopup(true);
                    }else{
                        setChangeUser(true);
                        const { access_token, refresh_token } = data.data;
                        setWallet(null);
                        SetRefreshToken(refresh_token);
                        SetAccessToken(access_token);
                        history("/")
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        const yupErrors = {};
                        yupErrors.username = t(error.response.data.message);
                        yupErrors.password = t(error.response.data.message);
                        formik.setErrors(yupErrors)
                    } else {
                        console.log(error)
                    }
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const { errors, touched, /*isSubmitting,*/isSubmitting, setSubmitting ,handleSubmit } = formik;

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.headerContainer}>
                <MobileHeaderLogo/>
                <NavLink to={"/"}><CloseCircleIcon/></NavLink>
            </div>
            <Switch
                names={[t("signIn"), t("create.account")]}
                links={["/login", "/register"]}
                actives={[true, false]}
            ></Switch>
            <FormikProvider value={formik}>
                <div className={styles.login}>
                    <Form autoComplete="on" onSubmit={handleSubmit}>
                        <Input
                            className={styles.input}
                            type={"text"}
                            name={"username"}
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            id={"username"}
                            error={errors.username}
                            touched={touched.username}
                            floatingLabel={t("username")}
                            autoComplete={"username"}
                            
                        />
                        <div className={styles.inputContainer}>
                        <Input
                            type={"password"}
                            name={"password"}
                            ref={passwordRef}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            id={"password"}
                            error={errors.password}
                            touched={touched.password}
                            floatingLabel={t("password")}
                            autoComplete={"current-password"}
                        />
                            <span
                                onClick={() => {
                                    showPassword();
                                }}
                                className={styles.eyeIcon}
                            >{isShow ? <EyeIcon /> : <EyeOffIcon/>}</span>
                        </div>
                        <button className={styles.loginButton + (isSubmitting ? " " + styles.disabled : "")} type="submit">
                            {isSubmitting? 
                                <div className={styles.spinLoading}>
                                    <div className={styles.spinLoadingOuterBlock}>
                                        <div className={styles.spinLoadingBlock + " " + styles.one}></div>
                                    </div>
                                    <div className={styles.spinLoadingOuterBlock}>
                                        <div className={styles.spinLoadingBlock + " " + styles.two}></div>
                                    </div>
                                    <div className={styles.spinLoadingOuterBlock}>
                                        <div className={styles.spinLoadingBlock + " " + styles.three}></div>
                                    </div>
                                </div>
                            :
                                t('signIn')
                            }
                        </button>
                    </Form>
                    <div className={styles.forgotPassword}>
                        <p className={styles.forgot +" "+styles.forgotText}>{t("forgot")}</p>
                        <NavLink  className={styles.forgot} to={"/send-user"}>
                            {t('username')}
                        </NavLink>
                        <p className={styles.or}>{t('forgot.or')}</p>
                        <NavLink  className={styles.forgot} to={"/password-reset"}>
                            {t('password')}?
                        </NavLink>
                    </div>
                </div>
            </FormikProvider>
            {showPopup && <TwoFA username={formik.values.username} password={formik.values.password} setSubmitting={setSubmitting}  setShowPopup={setShowPopup} />}
        </div>
    );
};

export default Login;
