import React, { useEffect, useState } from "react";
import "./App.css";
// import Routes from "./Pages/Desktop/Layout/Routes/Routes";
import MobileRoutes from "./Pages/Mobile/Layout/Routes/Router";
// import Header from "./Pages/Desktop/Layout/Header/Header";
import MobileHeader from "./Pages/Mobile/Layout/Header/Header";
// import Footer from "./Pages/Desktop/Layout/Footer/Footer";
import MobileFooter from "./Pages/Mobile/Layout/Footer/Footer";
// import { IsMobileBrowser } from "./Services/common";
// import ContactPopup from "./Components/ContactPopup/ContactPopup";
// import AgeCheck from "./Components/AgeCheck/AgeCheck";
// import ConfirmCookies from "./Components/ConfirmCookies/ConfirmCookies";
// import { GetKeyWords, GetPartners } from "./Services/service";
// import { Helmet } from "react-helmet";
// import { Route, Switch } from "react-router";
// import GameDemo from "./Components/GameDemo/GameDemo";
import ScrollToTop from './Components/ScrollToTop/scrollToTop'
import { useLocation } from "react-router-dom";
import { IsMobileBrowser } from "./Services/common";
import MobileView from "./Components/MobileView/MobileView";
import ConfirmCookies from "./Components/ConfirmCookies/ConfirmCookies";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Loader from "./assets/images/loader-setanta.json"
import { isMobile } from "react-device-detect";
import NotificationPopup from "./Components/Mobile/NotificationPopup/NotificationPopup";
import { NotificationPopupProvider } from "./Services/notificationPopupProvider";




export const App = () => {

  const [showConfirmCookies, setShowConfirmCookies] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation()
  // const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const isMobileBrowser = isMobile;
  
  function confirmCookiesShow() {
    return localStorage.getItem("confirmCookies");
  }

  function handleCloseConfirmCookies() {
    localStorage.setItem("confirmCookies", "true");
    setShowConfirmCookies(false);
  }

  useEffect(() => {
    if (location.pathname === '/login' || location.pathname.includes('/register') || location.pathname.includes('/menu')|| location.pathname.includes('/footer') || location.pathname.includes('/password-reset') || location.pathname === '/game' || location.pathname === '/send-user' ) {
      setShowHeader(false)
    } else {
      setShowHeader(true)
    }

    if (location.pathname !== "/game") {
      document.body.classList.add('rotate');
    } else {
      document.body.classList.remove('rotate');
      
    }
  }, [location])
  

  return (
    <>
        <>
        <ScrollToTop />
            <div className="main-container">
              {showHeader && <MobileHeader />}
              {!isMobileBrowser ?
                <MobileView>
                  <div className={"mainContainer"}>
                    <MobileRoutes />
                  </div>
                </MobileView>
                :
                <div className={"mainContainer-mobile"}>
                  <MobileRoutes />
                </div>
              }
              {showConfirmCookies && !confirmCookiesShow() && (
              <ConfirmCookies onClose={() => handleCloseConfirmCookies()} />
              )}
              {showHeader && <MobileFooter />}
            </div>
        </>
    </>
  );
};
