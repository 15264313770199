import { NavLink } from "react-router-dom"
import styles from './Switch.module.css'

const Switch = ({actives, links, names}) => {
    return (
        <header className={styles.switchContainer}>
            <NavLink className={styles.switchContainerElement + (actives[0] ? " " + styles.active : "")} to={links[0]} >{names[0]}</NavLink>
            <NavLink className={styles.switchContainerElement + (actives[1]? " " + styles.active : "")} to={links[1]} >{names[1]}</NavLink>
        </header>
    )
}

export default Switch