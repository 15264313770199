import { useEffect, useRef, useState } from "react";
import styles from "./NoCards.module.css";
import { useTranslation } from "react-i18next";
import WarningPopup from "../WarningPopup/WarningPopup";
import { useNavigate } from "react-router-dom";


const NoCards = ({setIsDepositPopupOpen, setIsWarningVisible,setIsDraggable}) => { 
    const [t] = useTranslation();

    function handleSubmit() {
        setIsWarningVisible(true)
        setIsDepositPopupOpen(false)
        setIsDraggable(true)
    }

    return (
        <div className={styles.noCardsWrapper}>
            <span className={styles.noCardsImg}></span>
            <h1 className={styles.noCardHeader}>{t("need.to.add.card.header")}</h1>
            <p className={styles.noCardText}>{t("need.to.add.card.text")}</p>
            <button type="submit" onClick={() => handleSubmit()} className={styles.submitButton}>
                    {t("continue")}
            </button>
        </div>
    )
}
export default NoCards;
