import { forwardRef, useEffect, useState } from 'react';
import styles from './Input.module.css'

const Input = forwardRef((props, ref) => {
    const [isTouched, setIsTouched] = useState(false);
    useEffect(() => {
        setIsTouched(props.isError)
    }, [props])
    return (
        <div className={styles.inputContainer}>
            <input
                name={props.name}
                value={props.value}
                maxLength={props.maxlength}
                onChange={props.onChange}
                onBlur={props.onBlur}
                className={styles.input + (props.error && (props.touched || isTouched) ? " " + styles.inputError : "") + (props.hidePassword ? " " + styles.hidePassword : "")}
                id={props.id}
                type={props.type}
                ref={ref}
                autoComplete={props.autoComplete}
                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
            />
            <div className={styles.background + (props.error && (props.touched || isTouched) ? " " + styles.inputError: "" )}></div>
            <span className={styles.floatingLabel + (props.error && (props.touched || isTouched) ? " " + styles.inputError: "" )}>{props.floatingLabel}</span>
            <p className={styles.error}>
            {props.error && (props.touched || isTouched) && props.error}
            </p>
      </div>
    )
})

export default Input