import React, { createContext, useState, useEffect, useContext } from 'react';
import { GetAccessToken, getCard, getMe, GetRefreshToken, GetSseUrl, getWallet, GetWebApiUrl, isLogedIn, RefreshToken, SetAccessToken, SetRefreshToken } from './common';
import { useLocation, useNavigate } from 'react-router-dom';
import currencySymbols from './currencySymbols.json'
import DixaSript from '../Components/Mobile/Dixa/Dixa';
import EventSourcePolyfill from 'eventsource-polyfill'
import { useNotificationPopup } from './notificationPopupProvider';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { showNotificationPopup, showSnackNotificationPopup } = useNotificationPopup();
  const [userData, setUserData] = useState();
  const [wallet, setWallet] = useState();
  const [changeUser, setChangeUser] = useState();
  const history = useNavigate()
  const location = useLocation()
  
  DixaSript();
  
  function connectSSE(tries = 0) {
    const accessToken = GetAccessToken();
    
    if (accessToken) {
      
      const sse = new EventSourcePolyfill(GetSseUrl() + '/api/v1/sse/connect', {
        xhrHeaders: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      
      sse.onmessage = e => {
        const newData = JSON.parse(e.data);
        console.log(newData);
      };

      sse.addEventListener('TRANSACTION_STATUS', (event) => { 
        const newData = JSON.parse(event.data);
        showSnackNotificationPopup({status: newData.status, text: newData.status});
        setWallet(prevWallet => ({
          ...prevWallet,
          balance: newData.balance ?? prevWallet.balance,  
        }));
      });

      sse.addEventListener('ACCOUNT_STATUS', (event) => { 
        const newData = JSON.parse(event.data);
        console.log(newData);
        if(newData.status === "full_block" || newData.status === "self_block" || newData.status === "temporary_block"){
          SetRefreshToken(null);
          SetAccessToken(null);
          setChangeUser(false);
          setUserData(false);
          setWallet(null);
          window._dixa_.invoke('purgeUserIdentity');
          history('/login');
        }

        if(newData.status === "product_block" && location.pathname.includes('/game')){
          history('/slots')
        }
        // showSnackNotificationPopup({status: newData.status, text: newData.status});
        // setWallet(prevWallet => ({
        //   ...prevWallet,
        //   balance: newData.balance ?? prevWallet.balance,  
        // }));
      });

      sse.addEventListener('NOTIFICATION', (event) => { 
        const newData = JSON.parse(event.data);
        showNotificationPopup(newData.content)
      });

      sse.addEventListener('NOTIFICATION_COUNT', (event) => { 
        const newData = JSON.parse(event.data);
        console.log(newData);
        setUserData(prevUserData => ({
          ...prevUserData,
          notificationCount: newData.count ?? 0,  
        }));
      });

      sse.onerror = (error) => {
        // console.log(error);
        if (error.data === "The server responded with 403") {
          if (tries < 4) {
            connectSSE(tries++);
          } else {
            history('/login');
          }
        }
      }
      sse.onopen = () => {
        console.log("Connected to the upstream SSE server.");
      };
    } else {
      history('/login')
    }
  }

  useEffect(() => {
    if (isLogedIn()) {
      getWallet(setWallet, wallet, currencySymbols).then((resp) => {
        if (resp === false || resp === "pending") {
          return;
        }
        
        getCard(setWallet, resp).then((resp) => {
          if (resp === false) {
            history('/login') 
          }
        })

      }).catch((err) => {
        console.log(err)
      })      

      connectSSE();

      getMe(setUserData).then((resp) => {
          if (resp === false) {
            history('/login')   
            return;
          } else {
            if (userData) {
              RefreshToken()
              history("/")
            }
          }
        }).catch((err) => {
          console.log(err)
        })
    }
  }, [changeUser]);
  
  return (
    <UserContext.Provider value={{ userData, setUserData, setChangeUser, wallet, setWallet }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };