import { useTranslation } from 'react-i18next';
import { PasportIcon, RefreshIcon, SelfyIcon, TrashIcon} from '../../../assets/svg/svg';
import styles from './Passport.module.css'

const Passport = ({setIsPopupOpen, setPopupOpenedFor, passportImage, setPassportImage, passportSelfyImage, setPassportSelfyImage}) => {
    const [t] = useTranslation()

    return (
        <div className={styles.PassportWrapper}>
            <div className={styles.divWrapper}>
                <div onClick={() => { if (!passportImage) { setIsPopupOpen(true); setPopupOpenedFor("PASSPORT") } }}  className={styles.card + " " + (passportImage ? styles.added: "")}>
                    {!passportImage && 
                        <div className={styles.cardIcon}>
                            <PasportIcon/>
                        </div>
                    }
                    {!passportImage && 
                        <p className={styles.text}>{t("upload.passport")}</p>
                    }
                    {passportImage && 
                        <div className={styles.imageWrapper}>
                            <span className={styles.imageBackground1}></span>
                            <img
                            alt="Not Found"
                            className={styles.image}
                            src={URL.createObjectURL(passportImage)}
                            />
                            <span className={styles.imageBackground2}></span>
                        </div>
                    }
                    {passportImage && 
                        <div className={styles.controlsWrapper}>
                            <span onClick={() => { setIsPopupOpen(true); setPopupOpenedFor("SELFY")}} className={styles.controlIcons}><RefreshIcon/></span>
                            <span className={styles.controlIcons} onClick={() => setPassportImage(null)}><TrashIcon/></span>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.divWrapper}>
                <div onClick={() => { if (!passportSelfyImage) { setIsPopupOpen(true); setPopupOpenedFor("SELFY") } }}  className={styles.card + " " + (passportSelfyImage ? styles.added: "")}>
                    {!passportSelfyImage && 
                        <div className={styles.cardIcon}>
                            <SelfyIcon/>
                        </div>
                    }
                    {!passportSelfyImage && 
                        <p className={styles.text}>{t("upload.selfy")}</p>
                    }
                    {passportSelfyImage && 
                        <div className={styles.selfyWrapper}>
                            <span className={styles.selfyBackground1}></span>
                            <img
                            alt="Not Found"
                            className={styles.selfyImage}
                            src={URL.createObjectURL(passportSelfyImage)}
                            />
                            <span className={styles.selfyBackground2}></span>
                        </div>
                    }
                    {passportSelfyImage && 
                        <div className={styles.controlsWrapper}>
                            <span onClick={() => { setIsPopupOpen(true); setPopupOpenedFor("SELFY")}} className={styles.controlIcons}><RefreshIcon/></span>
                            <span className={styles.controlIcons} onClick={() => setPassportSelfyImage(null)}><TrashIcon/></span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Passport;