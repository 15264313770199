import { isMobile } from "react-device-detect";
import { useMedia } from "use-media";
import { DeleteActivity, DeleteCard, GetCards, GetMe, GetNotifications, GetOneNotification, GetWallet, PatchNotifications, PatchPhoneUpdateCheck, PatchUser, PostActivity, PostDefenceCode, PostImage, PostImageSubmit, PostPasswordUpdateCheck, PostRefreshToken, PostSendPasswordUpdateCode, PostSendPaymentCode, PostSendPhoneUpdateCode, PostWallet } from "./service";

let ssoUrl = process.env.REACT_APP_SSO_URL
let paymentUrl = process.env.REACT_APP_PAYMENT_URL
let webApiUrl = process.env.REACT_APP_WEBAPI_URL
let veriffUrl = process.env.REACT_APP_VERIFF_URL
let sseUrl = process.env.REACT_APP_SSE_URL

console.log(process.env);


// const locale = {};

export const GetPaymentUrl = () => {
    return paymentUrl;
};

export const GetWebApiUrl = () => {
    return webApiUrl;
};

export const GetVeriffUrl = () => {
    return veriffUrl;
};

export const GetSsoUrl = () => {
    return ssoUrl;
};

export const GetSseUrl = () => {
    return sseUrl;
};

// export const GetStaticContentBaseUrl = () => {
//     return staticContentUrl;
// };
export const IsLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        window.location.hostname === "[::1]" ||
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
);

export const ConsoleLog = (msg) => {
    if (!IsLocalhost) return;
    console.log(msg);
};

export const GetCurrentTime = (time) => {
    let countDownDate = new Date(time).getTime();
    let now = new Date().getTime();

    let distance = countDownDate - now;
    let expired = distance < 0;

    let days = expired ? 0 : Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = expired
        ? 0
        : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = expired
        ? 0
        : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = expired ? 0 : Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds, expired };
};

// export const SetItemToLocalStorage = (key, data) => {
//     localStorage.setItem(key, JSON.stringify(data));
// }

export const SetRefreshToken = (refreshToken) => {
    let date = new Date();
    date.setTime(date.getTime() + (400 * 24 * 60 * 60 * 1000));
    let expires = "expires=" + date.toUTCString();  
    document.cookie = `refreshToken=${refreshToken}; path=/; secure; samesite=strict; ${expires}`;
};

export const SetAccessToken = (accessToken) => {
    let date = new Date();
    date.setTime(date.getTime() + (400 * 24 * 60 * 60 * 1000));
    let expires = "expires=" + date.toUTCString();  
    document.cookie = `accessToken=${accessToken}; path=/; secure; samesite=strict; ${expires}`;
};

export const RefreshToken = async () => {
    const refreshToken = GetRefreshToken();
    let isRefreshed;
    await PostRefreshToken(refreshToken)
        .then((resp) => {
            SetAccessToken(resp.data.access_token);
            isRefreshed = true;
        })
        .catch((error) => {
            SetAccessToken(null);
            SetRefreshToken(null);
            window._dixa_.invoke('purgeUserIdentity');
            isRefreshed = false;
        });
    return isRefreshed;
};
// export const GetItemFromLocalStorage = (key) => {
//     return JSON.parse(localStorage.getItem(key));
// }

export const GetRefreshToken = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split("; refreshToken=");
    if (parts.length === 2) {
        const token = parts.pop().split(";").shift();
        if (token !== "null") {
            return token;
        } else {
            return false;
        }
    }
    return false;
};

export const GetAccessToken = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split("; accessToken=");
    if (parts.length === 2) {
        const token = parts.pop().split(";").shift();
        if (token !== "null") {
            return token;
        } else {
            return false;
        }
    }
    return false;
};

function jwtDecode(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join("")
    );

    return JSON.parse(jsonPayload);
}

export const isValidToken = (accessToken) => {
    if (!accessToken) return false;
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
};

export const SetCategoryTitleToDocument = (title) => {
    document.title = title;
};

export const GetGameRedirectUrl = (gameName) => {};

export const IsMobileBrowser = () => {
    const minResolution = useMedia("(max-width: 900px)");
    return isMobile || minResolution;
};

export const isLogedIn = () => {
    const refreshToken = GetRefreshToken();
    const accessToken = GetAccessToken();
    if (refreshToken && accessToken && refreshToken !== undefined && accessToken !== undefined) {
        return true;
    } else {
        return false;
    }
};

export const getCard = async (setWallet, wallet, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await GetCards(accessToken)
            .then((data) => {
                setWallet({ ...wallet, cards: data.data});
                result = true;
            })
            .catch(async (error) => {
                if (error.response && error.response.status === 403) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                 return getCard(setWallet, wallet, tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                }
            });
    }else {
        result = false
    }
    return result;
};

export const  addToActivity = async (id, type, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PostActivity(accessToken, type, id)
            .then((resp) => {
                result = true
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 403) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return addToActivity(id, type, tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                }
            });
    }else {
        result = false
    }
    return result;
}

export const  removeFromActivity = async (id, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await DeleteActivity(accessToken, id)
            .then((resp) => {
                result = true
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 403) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return removeFromActivity(id, tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                }
            });
    }else {
        result = false
    }
    return result;
}


export const  getMe = async (setUserData ,tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await GetMe(accessToken)
            .then((resp) => {
                setUserData(resp.data);
                window._dixa_.invoke("setUserIdentity", { type: "verified", payload: { type: "jwe", token: resp.data.dixaToken } });
                result = true;
            })
            .catch(async (error) => {
                if (error.response && error.response.status === 401) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return getMe(setUserData, tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                }
            });
    }else {
        result = false
    }

    return result;
}

export const openGame = async (gameId, gameUid, userId, userName, lang, portalCode, openMode, setGameLink, provider, currency, tries = 0) => {
    if (!isLogedIn()) {
        return false;
    }

    function maskUsername(username) {
        if (username.length <= 2) {
            // If the username is too short, return it as is
            return username;
        }
        // Replace middle characters with asterisks
        return username[0] + '*'.repeat(username.length - 2) + username[username.length - 1];
    }

    let providerDefenceEnpoint;
    switch (provider) {
        case "EGT_AMUSNET":
            providerDefenceEnpoint = "egt-defence-code";
            break;
        case "EGT_DIGITAL":
            providerDefenceEnpoint = "egt-defence-code";
            break
        case "AVIATOR":
            providerDefenceEnpoint = "aviator-token";
            break
        case "PRAGMATIC":
            providerDefenceEnpoint = "pragmatic-url";
            break
        default:
            break;
    }
    let response = "pending";
    let defenceCode;
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PostDefenceCode(accessToken,providerDefenceEnpoint,provider,gameId,userId,lang)
            .then((resp) => {
                if (resp) {
                    defenceCode = resp.data;
                    response = true;
                }
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 400) {
                    response = "blocked";
                }
                if (error.response && error.response.status === 403) {
                    await RefreshToken().then((resp) => {
                        if (!resp) {
                            response = false;
                        } else {
                            if (tries < 4) {
                                openGame(gameId, gameUid, userId, userName, lang, portalCode, openMode, setGameLink, provider, currency, tries = 0);
                            } else {
                                response = false;
                            }
                        }
                    });
                }
            });
    } else {
        response = false;
    }
    const country = "ge"
    if (response === true) {
        let gameLink;
        switch (provider) {
            case "EGT_AMUSNET":
                gameLink = `https://staging-pod.games.amusnet.io/api/v2/MGL?defenceCode=${defenceCode}&playerId=${userId}&portalCode=${portalCode}&screenName=${maskUsername(userName)}&language=${lang}&country=${country}&gameId=${gameId}&client=mobile&closeurl="https://dev.bet.aviator.studio/"`
                break;
            case "EGT_DIGITAL":
                gameLink = `https://aviatorbetge-gc-staging-bgsp.egt-ong.com/?sessionToken=${defenceCode}&casinoId=${portalCode}&playerId=${userId}&gameKey=${gameId}&currencyCode=${currency}&language=${lang}&closeUrl="https://dev.bet.aviator.studio/"&demo="FALSE"`
                break;
            case "AVIATOR":
                gameLink = `https://staging.crush.playcom.ge/?token=${encodeURIComponent(defenceCode)}&providerId=670393b078aa9c67fe4755a5&currency=${currency}&language=${lang}&gameId=${gameId}`
                break;
            case "PRAGMATIC":
                gameLink = defenceCode.gameURL
                break;
            default:
                break;
        }
        addToActivity(gameUid, "last_played");
        if (openMode === "") {
            setGameLink(gameLink);
        } else {
            window.open(gameLink,'_blank')
        }
    }

    return response;
}

export const  postWallet = async (amount, type, processorName, cardId, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PostWallet(accessToken, amount, type, processorName, cardId)
            .then((resp) => {
                result = resp.data.orderId
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 403) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return PostWallet(amount, type, processorName, cardId, tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                }
            });
    } else {
        result = false
    }
    return result;
}

export const  getWallet = async (setWallet, wallet, currencySymbols ,tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await GetWallet (accessToken)
            .then((resp) => {
                setWallet({ ...wallet, ...resp.data , showBalance: true, currencySymbol: currencySymbols[resp.data.currency] });
                result = { ...wallet, ...resp.data, showBalance: true, currencySymbol: currencySymbols[resp.data.currency] };
            })
            .catch(async (error) => {
                if (error.response && error.response.status === 403) {
                     result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return getWallet(setWallet,wallet, currencySymbols, tries + 1);
                            } else {
                                setWallet(null);
                                return false
                            }
                        } else {
                            setWallet(null);
                            return false;
                        }
                    });
                }
            });
    } else {
        setWallet(null);
        result = false
    }
    return result;
}

export const  deleteCard = async (id, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await DeleteCard(id, accessToken)
            .then((resp) => {
                result = true;
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 403) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return deleteCard(id, tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                }
            });
    }else {
        result = false
    }
    return result;
}

export const phoneUpdateCheck = async (phone, code, personalId, password, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PatchPhoneUpdateCheck(accessToken, phone, code, personalId, password)
            .then((resp) => {
                if (resp) {
                    result = {phone: resp.data.phone };
                } else {
                    result = false;
                }
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 401) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return phoneUpdateCheck(phone, code, personalId, password, tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                } else {
                    result = error   
                }
            });
    }else {
        result = false
    }
    return result;
}

export const passwordUpdateCheck = async (currentPassword, newPassword, code, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PostPasswordUpdateCheck(accessToken, currentPassword, newPassword, code)
            .then((resp) => {
                result = true;
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 403) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return passwordUpdateCheck(currentPassword, newPassword, code, tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                } else {
                    result = error   
                }
            });
    }else {
        result = false
    }
    return result;
}

export const passwordUpdateSend = async (tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PostSendPasswordUpdateCode(accessToken)
            .then((resp) => {
                result = true;
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 401) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return passwordUpdateSend(tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                } else {
                    result = error   
                }
            });
    }else {
        result = false
    }
    return result;
}

export const phoneUpdateSend = async (phone, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PostSendPhoneUpdateCode(accessToken, phone)
            .then((resp) => {
                result = true;
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 401) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return phoneUpdateSend(phone, tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                } else {
                    result = error   
                }
            });
    }else {
        result = false
    }
    return result;
}

export const addCardSend = async (tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PostSendPaymentCode(accessToken)
            .then((resp) => {
                result = true;
            })
            .catch(async(error) => {
                if (error.response && error.response.status === 401) {
                    result = await RefreshToken().then((resp) => {
                        if (resp) {
                            if (tries < 4) {
                                return addCardSend(tries + 1);
                            } else {
                                return false
                            }
                        } else {
                            return false;
                        }
                    });
                } else {
                    result = error   
                }
            });
    }else {
        result = false
    }
    return result;
}

export const editUserInfo = async (key, value, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PatchUser(accessToken, key, value)
        .then((resp) => {
            result = true;
        })
        .catch(async(error) => {
            if (error.response && error.response.status === 403) {
                result = await RefreshToken().then((resp) => {
                    if (resp) {
                        if (tries < 4) {
                            return editUserInfo(key, value, tries + 1);
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                });
            }
        });
    }else {
        result = false
    }
    return result;
}

export const sendImage = async (sessionId, base64Image, docType, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PostImage(accessToken, sessionId, base64Image, docType)
        .then((resp) => {
            result = true;
        })
        .catch(async(error) => {
            if (error.response && error.response.status === 403) {
                result = await RefreshToken().then((resp) => {
                    if (resp) {
                        if (tries < 4) {
                            return sendImage(sessionId, base64Image, docType, tries + 1);
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                });
            }
        });
    }else {
        result = false
    }
    return result;
}

export const imageSubmit = async (sessionId, tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PostImageSubmit(accessToken, sessionId)
        .then((resp) => {
            result = true;
        })
        .catch(async(error) => {
            if (error.response && error.response.status === 403) {
                result = await RefreshToken().then((resp) => {
                    if (resp) {
                        if (tries < 4) {
                            return imageSubmit(sessionId, tries + 1);
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                });
            }
        });
    }else {
        result = false
    }
    return result;
}


export const getNotifications = async (setNotifications,tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await GetNotifications(accessToken)
        .then((resp) => {
            setNotifications(resp.data)
            // console.log(resp.data);
            result = true;
        })
        .catch(async(error) => {
            if (error.response && error.response.status === 403) {
                result = await RefreshToken().then((resp) => {
                    if (resp) {
                        if (tries < 4) {
                            return getNotifications(setNotifications, tries + 1);
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                });
            }
        });
    }else {
        result = false
    }
    return result;
}

export const readNotifications = async (id,tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await PatchNotifications(accessToken, id)
        .then((resp) => {
            result = true;
        })
        .catch(async(error) => {
            if (error.response && error.response.status === 403) {
                result = await RefreshToken().then((resp) => {
                    if (resp) {
                        if (tries < 4) {
                            return readNotifications(id, tries + 1);
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                });
            }
        });
    }else {
        result = false
    }
    return result;
}

export const openOneNotification = async (id,tries = 0) => {
    let result = "pending";
    const accessToken = GetAccessToken();
    if (accessToken) {
        await GetOneNotification(accessToken, id)
        .then((resp) => {
            result = resp;
        })
        .catch(async(error) => {
            if (error.response && error.response.status === 403) {
                result = await RefreshToken().then((resp) => {
                    if (resp) {
                        if (tries < 4) {
                            return openOneNotification(id, tries + 1);
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                });
            }
        });
    }else {
        result = false
    }
    return result;
}