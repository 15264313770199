import { useContext, useRef, useState } from "react";
import styles from "./TwoFA.module.css";
import { CloseIcon } from "../../../assets/svg/svg";
import { useTranslation } from "react-i18next";
import {  useFormik } from "formik";
import { SetAccessToken, SetRefreshToken } from "../../../Services/common";
import { UserContext } from "../../../Services/userContext";
import { useNavigate } from "react-router-dom";
import { PostSignIn } from "../../../Services/service";


const TwoFA = ({setShowPopup, userInput , username, password}) => {
    const wrapperRef = useRef(null);
    const [fadeOut, setFadeOut] = useState(false);
    const [t] = useTranslation();
    const [isError, setIsError] = useState(false)
    const { setChangeUser } = useContext(UserContext);
    let history = useNavigate();

    // useEffect(() => {
    //     inputRef.current.focus();
    // }, []);

    function hidePopup(element, isIcon) {
        if (element.target === wrapperRef.current || isIcon) {
            setFadeOut(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 500);
        }
    }

    function inputChange(input) {
        const regex = /^[0-9]$/;
        input.preventDefault();
        input.stopPropagation();
        if (input.keyCode === 8 && input.target.value === "") {
            if (input.target.parentElement.previousElementSibling) {
                input.target.parentElement.previousElementSibling.children[0].focus()
                return;
            }
        } else if (input.keyCode === 8) {
            setFieldValue(input.target.name, "")
            return;
        }

        if (regex.test(input.key.toString())) {
            setFieldValue(input.target.name, input.key)
            // if (isError) {
            //     setIsError(false);
            // }
            if (input.target.parentElement.nextElementSibling) {
                input.target.parentElement.nextElementSibling.children[0].focus()
            } else {
                const code = formik.values.number1 + formik.values.number2 + formik.values.number3 + input.key
                PostSignIn(username, password, code)
                    .then((data) => {
                        setChangeUser(true);
                        const { access_token, refresh_token} = data.data;
                        SetRefreshToken(refresh_token);
                        SetAccessToken(access_token);
                        history("/")
                        setShowPopup(false);
                    })
                    .catch((error) => {
                        if (error.response && error.response.data.message === "OTP_CODE_NOT_VALID") {
                            setIsError(true)
                        }
                    })
            }
        }
    }

    const formik = useFormik({
        initialValues: {
        },
    });

    // function handleSubmit(params) {
        // const code = formik.values.number1 + formik.values.number2 + formik.values.number3 + formik.values.number4
        // setIsTwoFaAccepted(true);
        // setShowPopup(false);
        // passwordUpdateCheck(userInput.oldPassword, userInput.password, code)
        //     .then((resp) => {
        //         if (resp === true) {
        //             setIsTwoFaAccepted(true);
        //             setFadeOut(true);
        //             setTimeout(() => {
        //                 setShowPopup(false);
        //             }, 500);
        //         } else {
        //             if (resp.response && resp.response.status === 409) {
        //                 const yupErrors = {};
        //                 yupErrors.personalId = t("id.exists");
        //                 setFadeOut(true);
        //                 setTimeout(() => {
        //                     setShowPopup(false);
        //                 }, 500);
        //                 setIsPasswordPopupOpen(true);
        //             }
        //             if (resp.response && resp.response.data.message === "ATTEMPTS_EXCEEDED") {
        //                 const yupErrors = {};
        //                 yupErrors.number = t("attempts.exceeded");
        //                 setFadeOut(true);
        //                 setTimeout(() => {
        //                     setShowPopup(false);
        //                 }, 500);
        //                 setIsPasswordPopupOpen(true);
        //             }
        //             if (resp.response && resp.response.data.message === "OTP_CODE_NOT_VALID") {
        //                 setIsError(true)
        //             }
        //         }
        //     })
    // }
    const {setFieldValue } = formik;


    return (
        <div onClick={(event) => hidePopup(event, false)} ref={wrapperRef} className={styles.popupComponent}>
            <div className={styles.popupContainer + (fadeOut ? " " + styles.fadeOut : "")}>   
                <div className={styles.headerDiv}>
                    <div className={styles.headerLeft}>
                        <h5 className={styles.headerText}>{t("SMS.code")}</h5>
                    </div>
                    <span
                        onClick={(event) => hidePopup(event, true)}
                        className={
                            styles.popupContainerIcon
                        }
                    ><CloseIcon/></span>
                </div>
                <div className={styles.form}>
                        <p className={styles.text}>{t("enter.code.from.mobile")} +9955990***39</p>
                            <div className={styles.inputs}>
                                <div className={styles.codeInputContainer}>
                                    <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")} name="number1" value={formik.values.number1} onKeyDown={(input) => { inputChange(input) }} type="number" maxLength="1" pattern="[0-9]*"></input>
                                    <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                                </div>
                                <div className={styles.codeInputContainer}>
                                    <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")} name="number2" value={formik.values.number2}  onKeyDown={(input) => {inputChange(input)}} type="number" maxLength="1" pattern="[0-9]*"></input>
                                    <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                                </div>
                                <div className={styles.codeInputContainer}>
                                    <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")}  name="number3" value={formik.values.number3} onKeyDown={(input) => {inputChange(input)}} type="number" maxLength="1" pattern="[0-9]*"></input>
                                    <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                                </div>
                                <div className={styles.codeInputContainer}>
                                    <input autoComplete={"off"} className={styles.inputElement + (isError ?  " " + styles.inputError : "")}  name="number4" value={formik.values.number4} onKeyDown={(input) => {inputChange(input)}} type="number" maxLength="1" pattern="[0-9]*"></input>
                                    <div className={styles.background + (isError ?  " " + styles.inputError : "")}></div>
                                </div>
                            </div>
                    </div>
            </div>
        </div>
    );
};

export default TwoFA;
