import React from 'react';
// import CustomSlider from "../../../Components/Slider/Slider";
// import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
// import MobileSlider from "../../../Components/Mobile/MobileSlider/MobileSlider";
import MobileGamesContainer from "../../../Components/Mobile/MobileGamesContainer/MobileGamesContainer";
// import CrashDescription from "../../../Components/Descriptions/CrashDescription";

const Crash = () => {
    return (
        <>
            {/* <CrashDescription/> */}
            {/* <MobileSlider pageName={'Crash'}/> */}
            <MobileGamesContainer categoryName={'Crash'}/>

        </>
    );
};

export default Crash;
