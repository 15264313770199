// import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
// import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { StrictMode } from 'react';
import ErrorBoundary from './Services/ErrorBoundary';
import { UserProvider } from './Services/userContext';
import './Services/i18n'
import { NotificationPopupProvider } from './Services/notificationPopupProvider';
import NotificationPopup from './Components/Mobile/NotificationPopup/NotificationPopup';
import NotificationSnack from './Components/Mobile/NotificationSnack/NotificationSnack';

//temp ip block START
// import axios from 'axios';
// import Login from './Login/Login';

// Whitelisted IPs
// const whitelist = ['178.134.52.90'/* ოფისი */, '188.129.213.131' /* გიორგი */, '155.133.90.89' /* თაკო */, "5.178.148.118" /* ლევანი */, "85.114.249.25" /* ანა */, "46.49.67.232" /* თორნიკე */, "109.172.176.130" /* D-Block */]; // Replace with your whitelisted IPs

// Function to fetch the user's IP address
// const fetchIp = async () => {
//   try {
//     const response = await axios.get('https://api.ipify.org?format=json');
//     return response.data.ip;
//   } catch (error) {
//     console.error('Error fetching IP address:', error);
//     return null;
//   }
// };

// const userIp = await fetchIp();

// temp ip block END


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById('root'));
// let isFullsecreen = false;
// document.getElementById('root').addEventListener("click", function () {
  // if (!isFullsecreen) {
  //   var
  //         el = document.documentElement
  //       , rfs =
  //              el.requestFullScreen
  //           || el.webkitRequestFullScreen
  //           || el.mozRequestFullScreen
  //   ;
  //   rfs.call(el);
  //   isFullsecreen = true;
  // }
// });



root.render(
  // <StrictMode>
    <ErrorBoundary>
      <BrowserRouter basename={baseUrl}>
          <NotificationPopupProvider>
            <UserProvider>
              {/* <Suspense fallback={<Loading/>}> */}
                <App />
                <NotificationPopup />
                <NotificationSnack/>
              {/* </Suspense> */}
            </UserProvider>  
          </NotificationPopupProvider>
      </BrowserRouter>
    </ErrorBoundary>
  //  </StrictMode>
);

