import { useTranslation } from 'react-i18next';
import { IDBackIcon, IDFrontIcon, RefreshIcon, SelfyIcon, TrashIcon} from '../../../assets/svg/svg';
import styles from './ID.module.css'

const ID = ({setIsPopupOpen ,setPopupOpenedFor, frontImage, backImage, selfyImage, setFrontImage, setBackImage, setSelfyImage}) => {
    const [t] = useTranslation()


    return (
        <div className={styles.IDWrapper}>
            <div className={styles.divWrapper}>
                <div onClick={() => { if (!frontImage) { setIsPopupOpen(true); setPopupOpenedFor("FRONT") }  }} className={styles.card}>
                    {!frontImage && 
                        <div className={styles.cardIcon}>
                            <IDFrontIcon/>
                        </div>
                    }
                    <p className={styles.text}>{t("front.side")}</p>
                    {frontImage && 
                        <div className={styles.imageWrapper}>
                            <span className={styles.idBackground1}></span>
                            <img
                            alt="Not Found"
                            className={styles.documentImage}
                            src={URL.createObjectURL(frontImage)}
                            />
                            <span className={styles.idBackground2}></span>
                        </div>
                    }
                    {frontImage && 
                        <div className={styles.controlsWrapper}>
                            <span onClick={() => { setIsPopupOpen(true); setPopupOpenedFor("FRONT")}} className={styles.controlIcons}><RefreshIcon/></span>
                            <span className={styles.controlIcons} onClick={() => setFrontImage(null)}><TrashIcon/></span>
                        </div>
                    }
                </div>
                <div onClick={() => { if (!backImage) { setIsPopupOpen(true); setPopupOpenedFor("BACK") } }} className={styles.card}>
                    {!backImage && 
                        <div className={styles.cardIcon}>
                            <IDBackIcon/>
                        </div>
                    }
                    <p className={styles.text}>{t("back.side")}</p>
                    {backImage && 
                        <div className={styles.imageWrapper}>
                            <span className={styles.idBackground1}></span>
                            <img
                            alt="Not Found"
                            className={styles.documentImage}
                            src={URL.createObjectURL(backImage)}
                            />
                            <span className={styles.idBackground2}></span>
                        </div>
                    }
                    {backImage && 
                        <div className={styles.controlsWrapper}>
                            <span onClick={() => { setIsPopupOpen(true); setPopupOpenedFor("BACK")}} className={styles.controlIcons}><RefreshIcon/></span>
                            <span className={styles.controlIcons} onClick={() => setBackImage(null)}><TrashIcon/></span>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.divWrapper}>
                <div onClick={() => { if (!selfyImage) { setIsPopupOpen(true); setPopupOpenedFor("SELFY") } }}  className={styles.card + " " + (selfyImage ? styles.selfy: "")}>
                    {!selfyImage && 
                        <div className={styles.cardIcon}>
                            <SelfyIcon/>
                        </div>
                    }
                    {!selfyImage && 
                        <p className={styles.text}>{t("upload.selfy")}</p>
                    }
                    {selfyImage && 
                        <div className={styles.selfyWrapper}>
                            <span className={styles.selfyBackground1}></span>
                            <img
                            alt="Not Found"
                            className={styles.selfyImage}
                            src={URL.createObjectURL(selfyImage)}
                            />
                            <span className={styles.selfyBackground2}></span>
                        </div>
                    }
                    {selfyImage && 
                        <div className={styles.controlsWrapper}>
                            <span onClick={() => { setIsPopupOpen(true); setPopupOpenedFor("SELFY")}} className={styles.controlIcons}><RefreshIcon/></span>
                            <span className={styles.controlIcons} onClick={() => setSelfyImage(null)}><TrashIcon/></span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ID