import { useTranslation } from "react-i18next";
import styles from "./ChagnePassword.module.css";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useRef, useState } from "react";
import Input from "../Input/Input";
import { EyeIcon, EyeOffIcon } from "../../../assets/svg/svg";
import { PostSendPasswordUpdateCode, PostSendPhoneUpdateCode } from "../../../Services/service";
import { GetAccessToken, passwordUpdateSend } from "../../../Services/common";
import { useNavigate } from "react-router-dom";


const ChangePassword = ({setShowTwoFA,setIsPasswordPopupOpen, setUserInput}) => {
	const { t } = useTranslation();
    const numberRef = useRef();
    const passwordRef = useRef();
    const passwordRepeatRef = useRef();
    const [hidePssword, setHidePssword] = useState(true);
    const [hideOldPssword, setHideOldPssword] = useState(true);
    const [hidePsswordRepeat, setHidePsswordRepeat] = useState(true);
    let history = useNavigate();


    const RegisterSchema = Yup.object().shape({
        oldPassword: Yup.string().required(t('password.required')),
        password: Yup.string().required(t('password.required')),
        passwordRepeat: Yup.string().equals([Yup.ref('password')],t('passwords.not.match')).required(t('password.repeat.required')),
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            password: "",
            newPassword: ""
        },
        validationSchema: RegisterSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data) => {  
            const accessToken = GetAccessToken();
            if (accessToken) {
                passwordUpdateSend(accessToken)
                    .then((resp) => {
                        if (resp === true) {
                            setIsPasswordPopupOpen(false);
                            setShowTwoFA(true);
                            setUserInput({ currentPassword: data.oldPassword, newPassword: data.password })
                        }
                    })
            } else {
				history("/login");
			}
        },
    });


    const { errors, touched, setFieldValue ,  /*isSubmitting,*/ handleSubmit } = formik;


	return (
		<div className={styles.changePhoneWrapper}>
			<FormikProvider value={formik}>
                <div className={styles.form}>
                    <Form onSubmit={handleSubmit}>  
                        <div className={styles.inputContainer}>
                            <Input
                                type={"text"}
                                name={"oldPassword"}
                                ref={passwordRef}
                                hidePassword={hideOldPssword}
                                value={formik.values.oldPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id={"oldPassword"}
                                error={errors.oldPassword}
                                touched={touched.oldPassword}
                                floatingLabel={t("old.password")}
                            />
                                <span
                                    onClick={() => {
                                        setHideOldPssword(!hideOldPssword)
                                    }}
                                    className={styles.eyeIcon}
                                >{!hideOldPssword ? <EyeIcon color={"rgba(119, 119, 119, 1)"}/> : <EyeOffIcon color={"rgba(119, 119, 119, 1)"}/>}</span>
                        </div>
                        <p>{t("enter.new.password")}</p>
                        <div className={styles.inputContainer}>
                            <Input
                                type={"text"}
                                name={"password"}
                                ref={passwordRef}
                                hidePassword={hidePssword}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id={"password"}
                                error={errors.password}
                                touched={touched.password}
                                floatingLabel={t("password")}
                            />
                                <span
                                    onClick={() => {
                                        setHidePssword(!hidePssword)
                                    }}
                                    className={styles.eyeIcon}
                                >{!hidePssword ? <EyeIcon color={"rgba(119, 119, 119, 1)"}/> : <EyeOffIcon color={"rgba(119, 119, 119, 1)"}/>}</span>
                        </div>
                        <div className={styles.inputContainer}>
                            <Input
                                type={"text"}
                                name={"passwordRepeat"}
                                ref={passwordRepeatRef}
                                hidePassword={hidePsswordRepeat}
                                value={formik.values.passwordRepeat}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                id={"passwordRepeat"}
                                error={errors.passwordRepeat}
                                touched={touched.passwordRepeat}
                                floatingLabel={t("repeat.password")}
                                autoComplete={"off"}
                            />
                                <span
                                    onClick={() => {
                                        setHidePsswordRepeat(!hidePsswordRepeat)
                                    }}
                                    className={styles.eyeIcon}
                                >{!hidePsswordRepeat ? <EyeIcon color={"rgba(119, 119, 119, 1)"}/> : <EyeOffIcon color={"rgba(119, 119, 119, 1)"}/>}</span>
                        </div>
                        <button className={styles.submitButton} type="submit">
                            {t("recive.code")}
                        </button>
                    </Form>
                </div>
            </FormikProvider>
		</div>
	);
};
export default ChangePassword;
