import React, { useState} from 'react';
import styles from "./Transaction.module.css";
import { useNavigate} from "react-router-dom";
import {  TransactionsIcon, RightArrow, BackIcon, ClockClose } from '../../../../assets/svg/svg';
import { useTranslation } from 'react-i18next';
import MobileHeaderLogo from '../../../../Components/Mobile/MobilHeaderLogo/MobileHeaderLogo';
import currencySymbols from '../../../../Services/currencySymbols.json'
import Popup from '../../../../Components/Mobile/Popup/Popup';
import ProvidersComponent from '../../../../Components/Mobile/Providers/Providers';


const Transactions = () => {
  const {t} = useTranslation()
  let history = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [isProviderPopupVisible, setIsProviderPopupVisible] = useState(false);
  const [isDatePopupVisible, setIsDatePopupVisible] = useState(false);
  const [isDetailedVisible, setIsDetailedVisible] = useState(false);
	const [providerSortTypes, setProviderSortTypes] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

  function sortByProvider(providers) {
  }

  function dateChange(params) {
    if (params.target.name === "trip-start") {
        if (params.target.value === "") {
            setStartDate(new Date().toISOString().split('T')[0])
        } else {
            setStartDate(params.target.value)
        }
    } else {
        if (params.target.value === "") {
            setEndDate(new Date().toISOString().split('T')[0])
        } else {
            setEndDate(params.target.value)
        }
    }
  }
  const data = [
    // {
    //   name: "Burning Hot",
    //   date: "12:33:21 - 12.06.2024",
    //   winAmount: "100",
    //   betAmount: "0.80",
    //   currency: "GEL"
    // },
    {
      type: "deposit",
      name: "NOVA",
      amount: "100",
      fee: "5",
      currency: "GEL",
      status: "successful",
    }
  ]

  return (
    <div className={styles.transactionWrapper}>
      <div className={styles.transactionComponent}>
        <div className={styles.transactionHeader}>
              <div onClick={() => { history('/menu') }}><BackIcon/></div>          
            <MobileHeaderLogo />
            <div className={styles.filler} />
        </div>
        <h1 className={styles.categoryTitle}>{t('transaction.history')}</h1>
        <header className={styles.switchContainer}>
          <div onClick={() => setActiveTab(0)} className={styles.switchContainerElement + (activeTab === 0 ? " " + styles.active : "")} >{t("financial")}</div>
          <div onClick={() => setActiveTab(1)} className={styles.switchContainerElement + (activeTab === 1 ? " " + styles.active : "")} >{t("games")}</div>
        </header>
        <div className={styles.transactionList}>
          <div className={styles.noData}>
            <span className={styles.noDataIcon}><ClockClose /></span>
            <p className={styles.noDataText}>{t("no.result.found")}</p>
          </div>
            {/* <div className={styles.categoryDescription}>23 ოქტომბერი</div>
            <div className={styles.categoryItem} onClick={() => setIsDetailedVisible(true)}>
              <div className={styles.categoryItemLeft}>
                <div className={styles.categoryHeader}>{activeTab === 0 && t(data[0].type) + ": "} {data[0].name}</div>
                <div className={styles.categoryFooter}>{activeTab === 0 ? t(data[0].status) : data[0].date}</div>
              </div>
              <div className={styles.categoryItemRight}>
                <div className={styles.categoryItemRightInner}>
                  <div className={styles.categoryHeader}>{activeTab === 0 ? data[0].amount + " " +currencySymbols[data[0].currency] : data[0].winAmount + " " + currencySymbols[data[0].currency]}</div>
                  <div className={styles.categoryFooter}>{activeTab === 0 ? t("fee") : t("bet")}: {activeTab === 0 ? data[0].fee + " " + currencySymbols[data[0].currency]: data[0].betAmount + " " + currencySymbols[data[0].currency]}</div>
                </div>
                <RightArrow color={"#646464"}/>
              </div>
            </div> */}
        </div>
      </div>
      <div className={styles.filters}>
        <div className={styles.filtersItem} onClick={() => setIsDatePopupVisible(true)}>
              <TransactionsIcon />
            <p className={styles.filtersText}>{t("time.filter")}</p>
        </div>
        <div className={styles.filtersItem} onClick={() => setIsProviderPopupVisible(true)}>
          <p className={styles.filtersText}>{t("providers")}</p>
        </div>
      </div>
        {isProviderPopupVisible &&
          <Popup header={"providers"} hidePopup={setIsProviderPopupVisible}>
            <ProvidersComponent hidePopup={setIsProviderPopupVisible} providerSortTypes={providerSortTypes} sortByProvider={sortByProvider}/>
          </Popup>
        }
        {isDatePopupVisible &&
          <Popup header={"time.filter"} hidePopup={setIsDatePopupVisible}>
            <div className={styles.dateComponent}>
              <div className={styles.header}>
                  <p className={styles.headerItem}>{t('from')}</p>
                  <p className={styles.headerItem}>{t('to')}</p>
              </div>
              <div className={styles.pickerComponent}>
                  <input className={styles.pickerItem} onChange={(e) => dateChange(e)} type="date" id="start" name="trip-start" value={startDate} min="2018-01-01" max={new Date().toISOString().split('T')[0]} />
                  <input className={styles.pickerItem} onChange={(e) => dateChange(e)} type="date" id="end" name="trip-end" value={endDate} min={startDate} max={new Date().toISOString().split('T')[0]} />
              </div>
              <div className={styles.submit} onClick={() => setIsDatePopupVisible(false)}>{t("save.changes")}</div>
            </div>
          </Popup>
        }
        {isDetailedVisible &&
        <Popup header={"details"} hidePopup={setIsDetailedVisible}>
          <div className={styles.transactionDetails}>
              <div className={styles.categoryItemDetails}>
                <div className={styles.categoryItemLeft}>
                  <div className={styles.categoryHeader}>{activeTab === 0 && t(data[0].type) + ": "} {data[0].name}</div>
                  <div className={styles.categoryFooter}>{activeTab === 0 ? t(data[0].status) : data[0].date}</div>
                </div>
                <div className={styles.categoryItemRight}>
                  <div className={styles.categoryItemRightInner}>
                    <div className={styles.categoryHeader}>{activeTab === 0 ? data[0].amount + " " +currencySymbols[data[0].currency] : data[0].winAmount + " " + currencySymbols[data[0].currency]}</div>
                    <div className={styles.categoryFooter}>{activeTab === 0 ? t("fee") : t("bet")}: {activeTab === 0 ? data[0].fee + " " + currencySymbols[data[0].currency]: data[0].betAmount + " " + currencySymbols[data[0].currency]}</div>
                  </div>
                </div>
              </div>
              <div className={styles.categoryItemDetails}>
                  <div className={styles.categoryItemLeft}>
                    <div className={styles.categoryHeader}>{activeTab === 0 && t(data[0].type) + ": "} {data[0].name}</div>
                    <div className={styles.categoryFooter}>{activeTab === 0 ? t(data[0].status) : data[0].date}</div>
                  </div>
                  <div className={styles.categoryItemRight}>
                    <div className={styles.categoryItemRightInner}>
                      <div className={styles.categoryHeader}>{activeTab === 0 ? data[0].amount + " " +currencySymbols[data[0].currency] : data[0].winAmount + " " + currencySymbols[data[0].currency]}</div>
                      <div className={styles.categoryFooter}>{activeTab === 0 ? t("fee") : t("bet")}: {activeTab === 0 ? data[0].fee + " " + currencySymbols[data[0].currency]: data[0].betAmount + " " + currencySymbols[data[0].currency]}</div>
                    </div>
                  </div>
              </div>
              <div className={styles.submitDetails} onClick={() => setIsDetailedVisible(false)}>{t("close")}</div>
          </div>
          </Popup>
        }
    </div>
  );
};

export default Transactions;
